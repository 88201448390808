import React, { useState } from "react";
import { Dialog, DialogContent } from "@react-md/dialog";
import { Card, CardHeader, CardTitle, CardContent } from "@react-md/card";
import SchoolSelectionForm from "./SchoolSelectionForm";
import "../../styles/components/teachers-schools/SchoolSelectModal.scss";
import NonTeacherSelection from "./NonTeacherSelection";

const SchoolSelectModal = (props) => {
  const [open, setOpen] = useState(true);
  const [schoolEmployee, setSchoolEmployee] = useState(true);
  const [updateSubmitted, setUpdateSubmitted] = useState(false);

  if (!props.visible || !open) {
    return <></>;
  }


  return (
    <>
      <Dialog
        id="school-select-modal"
        visible={props.visible && open}
        onRequestClose={() => setOpen(false)}
        aria-labelledby="dialog-title"
        className="school-select-modal"
      >
        {schoolEmployee && !updateSubmitted ? (
          <>
            <span
              id="school-select-modal-title"
              className="sr-only"
              onClick={() => setSchoolEmployee(!schoolEmployee)}
            >
              Select a School
            </span>
            <DialogContent className="school-select-modal__main">
              <Card
                className="school-select-modal__card"
                id="school-select-modal-card"
                // onClick={props.enableQuote}
              >
                <CardHeader className="teamCard-text">
                  <div className="school-select-modal__header">
                    <CardTitle className="school-select-modal__title">
                      Select a School
                    </CardTitle>
                    <a
                      className="school-select-modal__not_school"
                      onClick={() => setSchoolEmployee(!schoolEmployee)}
                    >
                      Not school-based?
                    </a>
                  </div>
                </CardHeader>
                <CardContent className="school-select-modal__content">
                  <SchoolSelectionForm
                    updateSubmitted={updateSubmitted}
                    setUpdateSubmitted={setUpdateSubmitted}
                  />
                </CardContent>
              </Card>
            </DialogContent>
          </>
        ) : !updateSubmitted ? (
          <>
            <DialogContent className="school-select-modal__main">
              <Card
                className="school-select-modal__card"
                id="school-select-modal-card"
                // onClick={props.enableQuote}
              >
                <CardHeader className="teamCard-text">
                  <div className="school-select-modal__header">
                    <a
                      className="school-select-modal__not_school"
                      onClick={() => setSchoolEmployee(!schoolEmployee)}
                    >
                      Back
                    </a>
                    <CardTitle className="school-select-modal__title">
                      Tell Us About Yourself
                    </CardTitle>
                  </div>
                </CardHeader>
                <CardContent className="school-select-modal__content">
                  <NonTeacherSelection
                    updateSubmitted={updateSubmitted}
                    setUpdateSubmitted={setUpdateSubmitted}
                  />
                </CardContent>
              </Card>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogContent className="school-select-modal__main">
              <Card
                className="school-select-modal__card"
                id="school-select-modal-card"
                // onClick={props.enableQuote}
              >
                <CardHeader className="teamCard-text">
                  <div className="school-select-modal__header">
                    <CardTitle className="school-select-modal__title">
                      Thank You!
                    </CardTitle>
                  </div>
                </CardHeader>
                <CardContent className="school-select-modal__content">
                  You may close this tab.
                </CardContent>
              </Card>
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
};

export default SchoolSelectModal;
