import { RiArrowDownSFill } from "@react-icons/all-files/ri/RiArrowDownSFill";
import React, { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Controller, useForm } from "react-hook-form";
import { Select, TextField, Checkbox } from "react-md";
import TimezonePicker from "react-bootstrap-timezone-picker";
import {
  OUTSIDE_US_OPTIONS,
  US_STATES,
  US_STATES_CODES,
} from "../../constants/states";

function SiteInfoForm(props) {
  const { control, register } = useForm();
  const filterBy = () => true;
  const [autocompleteValue, setAutocompleteValue] = useState("hey");
  const {
    isDistrict,
    school,
    streetAddress,
    schoolOrDistrictName,
    country,
    city,
    state,
    isSubmitting,
    typeaheadRef,
    isLoading,
    schoolOptions,
    stateOrProvince,
    postalCode,
    timezone,
    contactName,
    contactEmail,
    locked,
    sendToOrders,
    forceNewEstimate,
  } = props;
  const {
    handleSearch,
    handleAutocompleteOnSelect,
    handleStateChange,
    handleStateOrProvinceChange,
    setStateOrProvince,
    setCountry,
    setSchool,
    setTimezone,
    setContactEmail,
    setContactName,
    setStreetAddress,
    setPostalCode,
    setCity,
    setSendToOrders,
    setForceNewEstimate,
  } = props;

  // Locked is used when we loaded information from NCES id so we dont want to allow them to change any data
  return (
    <>
      <h2 className="get-quote-form-subHeader">
        {isDistrict ? "District" : "School"} information
      </h2>
      <div id="school-row-id-1" className="row">
        {!locked && <div className="column">
          <Controller
            control={control}
            name="qrStateField"
            defaultValue=""
            rules={{
              required: "Please select a state.",
            }}
            disabled={isSubmitting}
            render={(props) => (
              <Select
                id="custom-select-8"
                label="State"
                {...props}
                options={US_STATES}
                value={state}
                onChange={(e) => {
                  props.onChange(e);
                  handleStateChange(e);
                }}
                disableLeftAddon={false}
                rightChildren={<RiArrowDownSFill className="dropDownArrow" />}
              />
            )}
          />
        </div>}
        {OUTSIDE_US_OPTIONS.indexOf(state) === -1 && state !== "" && !locked ? (
          <>
            <div id="schoolOrDistrictName-column" className="column">
              <AsyncTypeahead
                id="qrSchoolOrDistrictName-text-field"
                name="qrSchoolOrDistrictName-text-field"
                labelKey="name"
                ref={typeaheadRef}
                inputProps={{
                  name: "autocomplete-school-or-district",
                  value: autocompleteValue,
                }}
                minLength={4}
                isLoading={isLoading && false}
                onChange={(text, e) => {
                  if (text.length > 0) handleAutocompleteOnSelect(text[0]);
                }}
                filterBy={filterBy}
                onSearch={handleSearch}
                options={schoolOptions}
                placeholder={
                  isDistrict
                    ? "Search district here..."
                    : "Search school here..."
                }
              />

              {school === "" && false ? (
                <p style={{ padding: "5px", fontSize: "0.9rem" }}>
                  Can't find your {isDistrict ? "district" : "school"}? Select{" "}
                  <i>"{isDistrict ? "District" : "School"} not found?</i>"
                </p>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      {(state !== "" && schoolOrDistrictName.name) ||
        OUTSIDE_US_OPTIONS.indexOf(state) >= 0 ? (
        <>
          <div id="school-row-id-2" className="row">
            <div id="schoolName-column" className="schoolColumn">
              <TextField
                id="schoolNametextfield"
                name="qrSchoolNametextfield"
                label={isDistrict ? "District Name" : "School Name"}
                onChange={(e) => setSchool(e.target.value)}
                value={school}
                ref={register({
                  required:
                    "Please enter a " +
                    (isDistrict ? "district" : "school") +
                    " name.",
                })}
                disabled={
                  (isSubmitting || OUTSIDE_US_OPTIONS.indexOf(state) === -1) &&
                  schoolOrDistrictName.name.indexOf("not found") === -1
                }
              />
            </div>
          </div>
          <div id="school-row-id-7" className="row">
            <div id="schoolAddress-column" className="streetAddressColumn">
              <TextField
                id="streetAddressTextfield"
                name="qrStreetAddressTextfield"
                label="Street Address"
                onChange={(e) => setStreetAddress(e.target.value)}
                ref={register({
                  required: "Please enter the address.",
                })}
                value={streetAddress}
                disabled={
                  (isSubmitting || OUTSIDE_US_OPTIONS.indexOf(state) === -1) &&
                  schoolOrDistrictName.name.indexOf("not found") === -1
                }
              />
            </div>
          </div>
          <div id="school-row-id-4" className="row">
            <div className="column">
              <TextField
                id="custom-country-text-field"
                name="qrCountryField"
                label="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                disabled={
                  (isSubmitting || OUTSIDE_US_OPTIONS.indexOf(state) === -1) &&
                  schoolOrDistrictName.name.indexOf("not found") === -1
                }
              />
            </div>

            <div id="custom-city-column" className="column">
              <TextField
                id="custom-city-text-field"
                name="customCityTextField"
                label="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                disabled={
                  (isSubmitting || OUTSIDE_US_OPTIONS.indexOf(state) === -1) &&
                  schoolOrDistrictName.name.indexOf("not found") === -1
                }
              />
            </div>
          </div>
          {country === "United States" ? (
            <div id="school-row-id-6" className="row">
              <div className="column">
                <Controller
                  control={control}
                  name="qrStateProvince"
                  defaultValue=""
                  rules={{
                    required: "Please select a state/province.",
                  }}
                  disabled={
                    isSubmitting || OUTSIDE_US_OPTIONS.indexOf(state) === -1
                  }
                  render={(props) => (
                    <Select
                      id="custom-select-10"
                      label="State/Province"
                      {...props}
                      options={
                        US_STATES_CODES
                          
                      }
                      value={stateOrProvince}
                      disabled={
                        isSubmitting || OUTSIDE_US_OPTIONS.indexOf(state) === -1 || locked
                      }
                      onChange={(e) => {
                        props.onChange(e);
                        handleStateOrProvinceChange(e);
                      }}
                      disableLeftAddon={false}
                      rightChildren={
                        !locked && <RiArrowDownSFill className="dropDownArrow" />
                      }
                    />
                  )}
                />
              </div>

              <div id="custom-postalCode-column" className="column">
                <TextField
                  id="postalCode-text-field"
                  name="postalCodeTextField"
                  label="Postal Code"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  // ref={register({ required })}
                  disabled={
                    (isSubmitting ||
                      OUTSIDE_US_OPTIONS.indexOf(state) === -1) &&
                    schoolOrDistrictName.name.indexOf("not found") === -1
                  }
                />
              </div>
            </div>
          ) : (
            <div id="school-row-id-6" className="row">
              <div className="column">
                <TextField
                  id="custom-state-provice-text-field"
                  name="qrStateProvince"
                  label="State/Province"
                  value={stateOrProvince}
                  onChange={(e) => setStateOrProvince(e.target.value)}
                  disabled={
                    (isSubmitting || OUTSIDE_US_OPTIONS.indexOf(state) === -1) &&
                    schoolOrDistrictName.name.indexOf("not found") === -1
                  }
                />
              </div>

              <div id="custom-postalCode-column" className="column">
                <TextField
                  id="postalCode-text-field"
                  name="postalCodeTextField"
                  label="Postal Code"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  disabled={
                    (isSubmitting ||
                      OUTSIDE_US_OPTIONS.indexOf(state) === -1) &&
                    schoolOrDistrictName.name.indexOf("not found") === -1
                  }
                />
              </div>
            </div>)}

          {false && schoolOrDistrictName.name === "School not found?" ? (
            <div id="school-row-id-5" className="row">
              <TimezonePicker
                id="timezone-picker-id"
                name="timezonePickerId"
                absolute={true}
                placeholder="Select timezone..."
                value={timezone}
                defaultValue=""
                onChange={(e) => setTimezone(e)}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {(state !== "" && schoolOrDistrictName.name) ||
        OUTSIDE_US_OPTIONS.indexOf(state) >= 0 ? (
        <>
          <h2 className="get-quote-form-subHeader">Contact information</h2>
          <div id="name-row-id" className="row">
            <div className="column">
              <TextField
                id="contactName-text-field"
                name="contactNameTextField"
                label="Contact Name"
                ref={register({
                  required: "Please enter a contact name.",
                })}
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
              />
            </div>
            <div className="column">
              <TextField
                id="contact-email-text-field"
                name="contactEmailField"
                label="Contact Email"
                type="email"
                ref={register({
                  required: "Please enter a valid contact field.",
                })}
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {localStorage.getItem("customer_service_token") && (
        <>
          <Checkbox
            id="send_orders"
            name="sendOrders"
            className="send_orders"
            label="Send to orders@deltamath.com"
            value={sendToOrders}
            checked={sendToOrders}
            onChange={(e) => {
              setSendToOrders(e.target.checked)
            }}
          />
          <Checkbox
            id="force_new_estimate"
            name="forceNewEstimate"
            className="force_new_estimate"
            label="Force new estimate"
            value={forceNewEstimate}
            onChange={(e) => {
              setForceNewEstimate(e.target.checked)
            }}
          />
        </>
      )}    
    </>
  );
}

export default SiteInfoForm;
